import React, { useState, useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout/Layout'
import FilterBar from '../components/ResourceLibrary/FilterBar'
import Loop from '../components/Loop'
import LoadingIcon from '../components/LoadingIcon'
import ResourcePagination from '../components/Archive/ResourcePagination'
import SelectSearch from 'react-select-search'
import Seo from '../components/Seo'

/**
 * Build a display version of our filters.
 */
function buildFilterDisplay( filters ) {
	const filterDisplayArray = []; 
	for ( const [ key, value ] of Object.entries(filters)) {
		if ( value !== '' ) {

			if ( key === 'page' ) {
				continue;
			}

			if ( value === 'posts' ) {
				filterDisplayArray.push( 'Articles' );
			} else if ( value === 'podcast_episode' ) {
				filterDisplayArray.push( 'Podcast Episode' );
			} else {
				filterDisplayArray.push( value );
			}
		}
	}

	return filterDisplayArray.join( ', ' );
}

const Resources = () => {

	/**
	 * Using this to know if we're on an initial load. We load and set default params if true
	 * and only update based on changes if it is not.
	 */
	const firstLoad = useRef(true);
	const [ isLoading, setisLoading ] = useState(false);

	/**
	 * Set default filters based on search parameters.
	 */	
	const [ category, setCategory ] = useState('');
	const [ format, setFormat ] = useState('');
	const [ topic, setTopic ] = useState('');
	const [ scripture, setScripture ] = useState('');
	const [ page, setPage ] = useState('');
	const [ search, setSearch ] = useState('');

	const [ filters, setFilters ] = useState( false )
	const [ filtersDisplay, setFiltersDisplay ] = useState([]);

	/**
	 * Pull the default resources from graphql
	 */
	const data = useStaticQuery( graphql`
		query resources {
			allWpContentNode(
				filter: { nodeType: { regex: "/Lecture|Discussion|Conference|PodcastEpisode|Sermon|Post/" } }
				sort: {order: DESC, fields: date}
				limit: 24
			) {
				nodes {
					nodeType
					... on WpPost {
						uri
						title
						excerpt
						date
						nodeType
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
									}
								}
							}
						}
					}
					... on WpSermon {
						uri
						title
						excerpt
						date
						nodeType
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
									}
								}
							}
						}
					}
					... on WpSermonClip {
						uri
						title
						excerpt
						date
						nodeType
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
									}
								}
							}
						}
					}
					... on WpLecture{
						uri
						title
						excerpt
						date
						nodeType
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
									}
								}
							}
						}
					}
					... on WpPodcastEpisode {
						uri
						title
						excerpt
						date
						nodeType
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
									}
								}
							}
						}
					}
					... on WpConference {
						uri
						title
						excerpt
						date
						nodeType
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
									}
								}
							}
						}
					}
					... on WpDiscussion {
						uri
						title
						excerpt
						date
						nodeType
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	const [ resources, setResources ] = useState( data.allWpContentNode.nodes );
	const [ totalResources, setTotalResources ] = useState( "3,000+" );
	const [ totalPages, setTotalPages ] = useState(); 

	useEffect( () => {

		if ( firstLoad.current ) {
			
			const searchParams = new URLSearchParams(location.search);
			
			var hasStartingFilter = false;
			var startingCategory = '';
			var startingFormat = '';
			var startingTopic = '';
			var startingScripture = '';
			var startingPage = '';
			var startingSearch = '';

			if ( searchParams.has( 'category' ) ) {
				startingCategory = searchParams.get( 'category' );
				hasStartingFilter = true;
				setCategory( startingCategory );
			}

			if ( searchParams.has( 'format' ) ) {
				startingFormat = searchParams.get( 'format' );
				hasStartingFilter = true;
				setFormat( startingFormat );
			}

			if ( searchParams.has( 'topic' ) ) {
				startingTopic = searchParams.get( 'topic' );
				hasStartingFilter = true;
				setTopic( startingTopic );
			}

			if ( searchParams.has( 'scripture' ) ) {
				startingScripture = searchParams.get( 'scripture' );
				hasStartingFilter = true;
				setScripture( startingScripture );
			}

			if ( searchParams.has( 'page' ) ) {
				startingPage = searchParams.get( 'page' );
				hasStartingFilter = true;
				setPage( startingPage );
			}

			if ( searchParams.has( 'search' ) )  {
				startingSearch = searchParams.get('search');
				hasStartingFilter = true;
				setSearch( startingSearch );
			}

			if ( hasStartingFilter ) {
				const startingFilters = {
					category: startingCategory,
					format: startingFormat,
					topic: startingTopic,
					scripture: startingScripture,
					page: startingPage,
					search: startingSearch
				}
	
				setFilters( startingFilters );
				setFiltersDisplay( buildFilterDisplay( startingFilters ) );
			}
		}

	}, []);

	/**
	 * Update the filters var when individual filters are updated.
	 */
	useEffect( () => {
		
		if ( ! firstLoad.current ) {

			const updatedFilters = {
				category: category,
				format: format,
				topic: topic,
				scripture: scripture,
				page: page,
				search: search
			}
		
			setFilters( updatedFilters );
			setFiltersDisplay( buildFilterDisplay( updatedFilters ) );

		} else {
			firstLoad.current = false;
		}

	}, [ category, format, topic, scripture, page, search ] );

	/**
	 * On changes to filters, query and update the resources.
	 */
	useEffect( () => {	

		if ( filters ) {
			const searchParams = new URLSearchParams(location.search);

			var hasFilter = false;

			for ( const [ key, value ] of Object.entries(filters) ) {
				if ( value ) {
					searchParams.set( key, value );
					hasFilter = true;
				}
			}

			if ( hasFilter ) {
				window.history.replaceState({}, '', `${location.pathname}?${searchParams}` );
			}

			async function resourceFetch() {
				
				setisLoading(true);

				const response = await window
					.fetch( `/api/resources/filter`, {
						method: `POST`,
						headers: {
							"content-type": "application/json",
						},
						body: JSON.stringify( filters ),
					})
					.then(res => res.json() )

					firstLoad.current = false;

					setResources( response.resources );
					setTotalResources( response.headers['x-wp-total'] );
					setTotalPages( response.headers[ 'x-wp-totalpages' ] );

					setisLoading( false );
			}

			resourceFetch();
		}

	}, [ filters ] );

	const scrollToTop = () => {
		window.scrollTo({
		  top: 0,
		  behavior: "smooth"
		});
	  };

	/**
	 * Update the category prop when the category is selected
	 * within the FilterBar component.
	 */
	function handleFilterCategory( filteredCategory ) {	
		setCategory( filteredCategory )
		setPage( 1 )
	}

	function handleFilterFormat( filteredFormat ) {	
		setFormat( filteredFormat )
		setPage( 1 )
	}

	function handleFilterScripture( filteredScripture ) {
		setScripture( filteredScripture )
		setPage( 1 )
	}

	function handleFilterTopic( filteredTopic ) {
		setTopic( filteredTopic )
		setPage( 1 );
	}

	function handleClearTopic() {
		window.history.replaceState({}, '', `${location.pathname}` );
		firstLoad.current = false;
		setTopic('')
		setFilters( filters );
	}

	function handleClearCategory() {
		window.history.replaceState({}, '', `${location.pathname}` );
		firstLoad.current = false;
		setCategory('')
		setFilters( filters );
	}

	function handleClearFormat() {
		window.history.replaceState({}, '', `${location.pathname}` );
		firstLoad.current = false;
		setFormat('')
		setFilters( filters );
	}

	function handleClearScripture() {
		window.history.replaceState({}, '', `${location.pathname}` );
		firstLoad.current = false;
		setScripture('')
		setFilters( filters );
	}

	function handleSetPagePrevious() {
		if ( page === '' ) {
			setPage( 2 )
		} else {
			var newPage = Number( page ) + 1;
			setPage( newPage );
		}
		
		scrollToTop();
	}

	function handleSetPageNext() {
		var newPage = Number( page ) - 1;
		setPage( newPage );
		scrollToTop();
	}

	function clearFilters() {

		setCategory('')
		setFormat('')
		setTopic('')
		setScripture('')
		setPage('')
		setSearch('')

		window.history.replaceState({}, '', `${location.pathname}` );
	}

	return (
		<Layout
			slug="resources"
		>

			<Seo title="Resources" />
		
			<FilterBar
				filterCategory={handleFilterCategory}
				category={category}
				clearCategory={handleClearCategory}
				filterTopic={handleFilterTopic}
				topic={topic}
				clearTopic={handleClearTopic}
				filterFormat={handleFilterFormat}
				format={format}
				clearFormat={handleClearFormat}
				filterScripture={handleFilterScripture}
				scripture={scripture}
				clearScripture={handleClearScripture}
			/>

			<h1 className="text-center py-16">
				Resources
			</h1>

			<div className="container mx-auto mb-8 uppercase tracking-wide lg:flex text-center lg:text-left">
				<div className="flex-1 font-semibold text-sm">
					{ filtersDisplay.length > 0 ? filtersDisplay : 'All Resources' }
					{ filtersDisplay.length > 0 && <span className="text-xs text-gray-600 underline mx-2 normal-case cursor-pointer" onClick={clearFilters}>Clear Filters</span> }
				</div>
				<div className="flex-2 text-xs"> 
					{ totalResources && totalResources > 24 || totalResources == '3,000+' ?
						'Showing 24 of ' + totalResources
					:
						'Showing ' + totalResources + ' of ' + totalResources
					}
				</div>
			</div>

			{ isLoading ?
				<LoadingIcon />

				: <Loop
					items={resources}
				/>
			}

			<ResourcePagination
				hasPrevious={page < totalPages || page == '' ? true : false }
				hasNext={page == 1 || page == '' ? false : true }
				setPageNext={handleSetPageNext}
				setPagePrevious={handleSetPagePrevious}
			/>
			
		</Layout>
	)
}

export default Resources