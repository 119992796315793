import React from "react"

const ResourcePagination = ( { hasPrevious, hasNext, setPagePrevious, setPageNext } ) => {

  return(
    <div className="container mx-auto my-16 flex">

      { hasPrevious ? 
        <div className="flex-1 ml-4">
          <button
            className="border border-gold-500 border-solid rounded-full py-2 px-6 text-green-900 uppercase text-sm tracking-widest"
            onClick={setPagePrevious}
          >
            Older Posts
          </button>
        </div> :
        ''
      }

      { hasNext ? 
        <div className="flex-1 flex justify-end mr-4">
          <button
            className="border border-gold-500 border-solid rounded-full py-2 px-6 text-green-900 uppercase text-sm"
            onClick={setPageNext}
          >
            Newer Posts
          </button>
        </div> :
        ''
      }
      
    </div>
  )
}

export default ResourcePagination
