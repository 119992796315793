import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SelectSearch from 'react-select-search'
import Fuse from 'fuse.js'
import TopicFilter from './TopicFilter'
import FormatFilter from './FormatFilter'
import ScriptureFilter from './ScriptureFilter'
import ClearIcon from '../svgs/clearIcon'
import SectionHeader from '../SectionHeader'
import AngleIcon from '../svgs/AngleIcon'

const FilterBar = ( {filterCategory, category, clearCategory, filterTopic, topic, clearTopic, filterFormat, format, clearFormat, filterScripture, scripture, clearScripture } ) => {

	const categoryOptions = [
		{
			name: 'Articles',
			value: 'posts'
		},
		{
			name: 'Sermons',
			value: 'sermon'
		},
		{
			name: 'Sermon Clips',
			value: 'sermon_clip'
		},
		{
			name: 'Discussions',
			value: 'discussion'
		},
		{
			name: 'Conferences',
			value: 'conference'
		},
		{
			name: 'Podcast Episodes',
			value: 'podcast_episode'
		},
		{
			name: 'Books',
			value: 'book'
		},
		{
			name: 'Book Reviews',
			value: 'book-review'
		},
		{
			name: 'Events',
			value: 'event'
		},
		{
			name: 'Lectures',
			value: 'lecture'
		},
	];

	const [ mobileToggled, setMobileToggled ] = useState( 'hidden' );

	const filtersClass = mobileToggled + ' mt-4 lg:mt-0 lg:flex flex-col lg:flex-row items-center lg:justify-center';

	return (
		<div className="bg-gold-100 w-100 py-4 lg:py-8">

				<button className="flex lg:hidden items-center w-full justify-center text-sm font-sans px-6 xl:px-0 font-semibold tracking-widest uppercase" onClick={ () => {
					if ( mobileToggled == 'flex' ) {
						setMobileToggled( 'hidden' )
					} else {
						setMobileToggled( 'flex' )
					}
				} }>
					<span className="mr-4">Filter Resources</span>
					<AngleIcon />
				</button>

			<div className={filtersClass}>
				<div className="relative w-full lg:w-auto">
					{ category != '' && 
						<button
							onClick={clearCategory}
							className="absolute left-7 top-3.5 z-10"
						>
							<ClearIcon />
						</button>
					}
					<AngleIcon 
						className="absolute right-10 lg:right-8 lg:left-48 top-6 lg:top-5 z-10"
					/>
					<SelectSearch
						value={category}
						options={categoryOptions}
						placeholder="Choose a Category"
						onChange={filterCategory}
					/>
				</div>

				<FormatFilter
					format={format}
					filterFormat={filterFormat}
					clearFormat={clearFormat}
				/>

				<TopicFilter 
					topic={topic}
					filterTopic={filterTopic}
					clearTopic={clearTopic}
				/>

				<ScriptureFilter
					scripture={scripture}
					filterScripture={filterScripture}
					clearScripture={clearScripture}
				/>
			</div>

		</div>
	)
}

export default FilterBar