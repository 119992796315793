import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SelectSearch from 'react-select-search'
import Fuse from 'fuse.js'
import ClearIcon from '../svgs/clearIcon'
import AngleIcon from '../svgs/AngleIcon'

const fuzzySearch = (options) => {
    const fuse = new Fuse(options, {
        keys: ['name', 'value', 'items.name'],
        threshold: 0.3,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }

        return fuse.search(value);
    };
}

const TopicFilter = ( { filterTopic, topic, clearTopic } ) => {

    const data = useStaticQuery(graphql`
		query TopicOptions {
			allWpTag(
                limit: 1500
            )  {
				edges {
					node {
						name
						slug
					}
				}
			}
		}
	`)

	var topicOptions = [];

	data.allWpTag.edges.map((topic, index) => {
		topicOptions.push( { name: topic.node.name, value:topic.node.slug  })
	});

    return (
		<div className="relative w-full lg:w-auto topic-filter">
			{ topic != '' && 
				<button
					onClick={clearTopic}
					className="absolute left-7 top-3.5 z-10"
				>
					<ClearIcon />
				</button>
			}
			<AngleIcon 
				className="absolute right-10 lg:right-8 lg:left-48 top-6 lg:top-5 z-10"
			/>
			<SelectSearch
				value={topic}
				options={topicOptions}
				placeholder="Choose a Topic"
				search
				filterOptions={fuzzySearch}
				onChange={filterTopic}
			/>
		</div>
    )
}

export default TopicFilter