import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SelectSearch from 'react-select-search'
import Fuse from 'fuse.js'
import ClearIcon from '../svgs/clearIcon'
import AngleIcon from '../svgs/AngleIcon'

const FormatFilter = ( { filterFormat, format, clearFormat } ) => {

    const data = useStaticQuery(graphql`
		query FormatOptions {
			allWpFormat {
				edges {
					node {
						name
						slug
					}
				}
			}
		}
	`)

	var formatOptions = [];

	data.allWpFormat.edges.map((format, index) => {
		formatOptions.push( { name: format.node.name, value:format.node.slug  })
	});

    return (
		<div className="relative w-full lg:w-auto format-filter">

			{ format != '' && 
				<button
					onClick={clearFormat}
					className="absolute left-7 top-3.5 z-10"
				>
					<ClearIcon />
				</button>
			}
			<AngleIcon 
				className="absolute right-10 lg:right-8 lg:left-48 top-6 lg:top-5 z-10"
			/>
			<SelectSearch
				value={format}
				options={formatOptions}
				placeholder="Choose a Format"
				onChange={filterFormat}
			/>
		</div>
    )
}

export default FormatFilter