import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SelectSearch from 'react-select-search'
import Fuse from 'fuse.js'
import ClearIcon from '../svgs/clearIcon'
import AngleIcon from '../svgs/AngleIcon'

const fuzzySearch = (options) => {
    const fuse = new Fuse(options, {
        keys: ['name', 'value', 'items.name'],
        threshold: 0.3,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }

        return fuse.search(value);
    };
}

const ScriptureFilter = ( { filterScripture, scripture, clearScripture } ) => {

    const data = useStaticQuery(graphql`
		query ScriptureOptions {
			allWpBookOfTheBible(
				sort: {fields: bibleBooksOrder___order, order: ASC}
			) {
				edges {
					node {
						name
						slug
					}
				}
			}
		}
	`)

	var scriptureOptions = [];

	data.allWpBookOfTheBible.edges.map((scripture, index) => {
		scriptureOptions.push( { name: scripture.node.name, value:scripture.node.slug  })
	});

    return (
		<div className="relative w-full lg:w-auto">
			{ scripture != '' && 
				<button
					onClick={clearScripture}
					className="absolute left-7 top-3.5 z-10"
				>
					<ClearIcon />
				</button>
			}	
			<AngleIcon 
				className="absolute right-10 lg:right-8 lg:left-48 top-6 lg:top-5 z-10"
			/>
			<SelectSearch
				value={scripture}
				options={scriptureOptions}
				placeholder="Book of the Bible"
				search
				filterOptions={fuzzySearch}
				onChange={filterScripture}
			/>
		</div>
    )
}

export default ScriptureFilter