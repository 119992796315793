import React from 'react'
import LogoIcon from './svgs/LogoIcon'

const LoadingIcon = () => {

    return (
        <div className="py-16 text-center w-100">
            <div>
                <LogoIcon className="animate-ping mx-auto" />
            </div>
        </div>
    )
}

export default LoadingIcon